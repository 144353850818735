
body.gate-404{
  padding-bottom: 0;
}
body.le-landing-page{
  background: #fff;
  .video-area{
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  }
}
